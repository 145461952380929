import React from "react"
import { Link, graphql } from "gatsby"
import SEO from "../components/seo"
import Img from "gatsby-image"
import "../styles/404.css"

class ThankYou extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      temp: null,
      count: 0
    }
  }
  render() {
    const {
      data
    } = this.props

    return ( 
      <>
        <SEO title="Formular indsendt" />
        <section id="e404" className="e404">
        
            <div className="e404--inner">
              <h2 className="heading">Tak for din besked!</h2>
              <p>Vi vil vende tilbage hurtigst muligt.</p>
              <p>I mellemtiden kan du læse mere om MB Racing her på <Link to="/" className="inline-click">sitet</Link>.</p>
            </div>

            <Img fluid={data.directusPageSetting._404_page_image.localFile.childImageSharp.fluid} className="e404--background" />
        
        </section>
      </>
    )}

}

export default ThankYou

export const query = graphql`
  query {
    directusPageSetting {
      _404_page_image {
        localFile {
          childImageSharp {
            fluid(quality: 75) {
                ...GatsbyImageSharpFluid_withWebp            
            }
          }
        }
      }
    }
  }
`